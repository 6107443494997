import styled from '@emotion/styled'

export const JoinClassForm = styled.form``

export const JoinClassFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const JoinClassFormInputLabel = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
`

export const JoinClassFormInputSubtitle = styled.sub`
  font-size: 0.8em;
  color: #777777;
  margin-top: -10px;
  margin-bottom: 10px;
`

export const JoinClassFormInput = styled.input`
  font-size: 16px;
  line-height: 1.2;
  height: 50px;
  border-radius: 31px;
  padding: 0 35px 0 35px;
`

export const JoinClassErrors = styled.div`
  color: red;
  align-self: flex-end;
`
