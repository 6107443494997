import React, { useEffect, useCallback } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { useQueryParam, StringParam } from 'use-query-params'
import { useFormik } from 'formik'
import { Helmet } from 'react-helmet'
import * as Yup from 'yup'
import { Global } from '@emotion/react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next'

import {
  JoinClassForm,
  JoinClassFormInputContainer,
  JoinClassFormInputLabel,
  JoinClassFormInput,
  JoinClassErrors,
  JoinClassFormInputSubtitle,
} from 'components/styleComponents/JoinComponents'
import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/styleComponents/StripeCheckout'
import LottieShape from 'components/General/LottieShape'
import AbstractLoginAnimation from 'components/General/Animations/AbstractLoginAnimation'
import LoadingAnimation from 'components/General/Loading/LoadingAnimation'
import GlobalStyles from 'components/styleComponents/GlobalStyles'

const GET_UNIMINUTO_TOKEN = gql`
  query getUniminutoToken($input: GetUniminutoTokenInput!) {
    getUniminutoToken(input: $input) {
      token
    }
  }
`
const REDIRECT_URL_TOKEN_PARAM = '?token='

const Join = () => {
  const { t, ready } = useTranslation('uniminutoJoinClassPage')
  const notifyError = useCallback(() => toast.error(t('errorMessage')), [t])

  const [session, setSession] = useQueryParam('session', StringParam)

  const [
    getUniminutoToken,
    {
      data: getUniminutoTokenData,
      loading: getUniminutoTokenLoading,
      error: getUniminutoTokenError,
    },
  ] = useLazyQuery(GET_UNIMINUTO_TOKEN)

  const formik = useFormik({
    initialValues: {
      session: session,
      displayName: '',
    },
    validationSchema: Yup.object({
      session: Yup.string()
        .length(10, t('sessionLengthValidation'))
        .required(t('formFieldRequired')),
      displayName: Yup.string()
        .min(2, t('displayNameMinValidation'))
        .max(20, t('displayNameMaxValidation'))
        .required(t('formFieldRequired')),
    }),
    onSubmit: async (values) => {
      setSession(values.session)
      await getUniminutoToken({
        variables: {
          input: {
            session: values.session,
            displayName: values.displayName,
          },
        },
      })
    },
  })

  useEffect(() => {
    if (
      getUniminutoTokenData &&
      getUniminutoTokenData.getUniminutoToken &&
      getUniminutoTokenData.getUniminutoToken.token &&
      typeof window !== 'undefined'
    ) {
      const redirectURL =
        process.env.GATSBY_UNIMINUTO_URL +
        session.toLowerCase() +
        REDIRECT_URL_TOKEN_PARAM +
        getUniminutoTokenData.getUniminutoToken.token
      window.location.assign(redirectURL)
    }
  }, [getUniminutoTokenData, session])

  useEffect(() => {
    if (getUniminutoTokenError) {
      notifyError()
    }
  }, [getUniminutoTokenError, notifyError])

  return (
    <>
      <ToastContainer
        limit={1}
        position="bottom-left"
        autoClose={8000}
        hideProgressBar={true}
      />
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <Global styles={GlobalStyles} />
      <main>
        <StripeCheckoutRedirectContainer>
          <ShapeContainer>
            <LottieShape size={500} animationData={AbstractLoginAnimation} />
          </ShapeContainer>
          {!ready ? null : (
            <StripeCheckoutContentContainer>
              <StripeCheckoutRedirectHeader>
                {t('formHeader')}
              </StripeCheckoutRedirectHeader>
              <JoinClassForm onSubmit={formik.handleSubmit}>
                <StripeCheckoutRedirectDescription>
                  <JoinClassFormInputContainer>
                    <JoinClassFormInputLabel>
                      {t('sessionInputLabel')}
                    </JoinClassFormInputLabel>
                    <JoinClassFormInput
                      id="session"
                      name="session"
                      type="text"
                      placeholder={t('sessionInputLabel')}
                      onChange={formik.handleChange}
                      value={formik.values.session}
                    />
                    {formik.touched.session && formik.errors.session ? (
                      <JoinClassErrors>{formik.errors.session}</JoinClassErrors>
                    ) : null}

                    <JoinClassFormInputLabel>
                      {t('displayNameInputLabel')}
                    </JoinClassFormInputLabel>
                    {t('displayNameInputSubtitle') ? (
                      <JoinClassFormInputSubtitle>
                        {t('displayNameInputSubtitle')}
                      </JoinClassFormInputSubtitle>
                    ) : null}
                    <JoinClassFormInput
                      id="displayName"
                      name="displayName"
                      type="text"
                      placeholder={t('displayNameInitialValue')}
                      onChange={formik.handleChange}
                      value={formik.values.displayName}
                    />
                    {formik.touched.displayName && formik.errors.displayName ? (
                      <JoinClassErrors>
                        {formik.errors.displayName}
                      </JoinClassErrors>
                    ) : null}
                  </JoinClassFormInputContainer>
                </StripeCheckoutRedirectDescription>
                {getUniminutoTokenLoading ? (
                  <LottieShape animationData={LoadingAnimation} size={50} />
                ) : (
                  <button type="submit" disabled={!getUniminutoTokenLoading}>
                    <StripeCheckoutRedirectButton>
                      {t('formSubmitButtonText')}
                    </StripeCheckoutRedirectButton>
                  </button>
                )}
              </JoinClassForm>
            </StripeCheckoutContentContainer>
          )}
        </StripeCheckoutRedirectContainer>
      </main>
    </>
  )
}

export default Join
